/* eslint camelcase: ["error", {properties: "never"}] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Badge, Button, Fade, Container, Row, Col, Form, Tab, Tabs, Modal} from 'react-bootstrap';
import {Formik} from 'formik';
import {FilePond} from 'react-filepond';
import CommonMetaTag from './common-meta-tag';
import DatePicker, {registerLocale} from 'react-datepicker';
import Dialog from 'react-bootstrap-dialog';
import {PayPalButton} from 'react-paypal-button-v2';
import {useTranslation} from 'react-i18next';
import Configuration from '../config';
import 'react-datepicker/dist/react-datepicker.css';
import it from 'date-fns/locale/it';
import {changeSubscription, reportBookings, reportSubscriptions, reportTransactions, changeStorageType, showStorageModal, hideStorageModal} from '../actions/player';
import PlayerNewSubscription from './player-new-subscription';
import PlayerInsufficientFunds from './player-insufficient-funds';
import {subscriptionKind, groupTypes, imageTypes} from '../constants';
import {showMessage} from '../actions/messages';
registerLocale('it', it);

const PlayerProfile = ({
  profile,
  save,
  deleteProfile,
  close, token,
  removeFile,
  topUpWalletSuccess,
  showNotClosePaypalMessage,
  partners, subscriptions,
  selectedPlayerGroups,
  changeAutorenew,
  archiveSubscription,
  showReplayerTV,
  createBookingReport,
  createTransactionReport,
  createSubscriptionReport,
  storagePrices,
  changeStorageType,
  isStorageModalVisible,
  showStorageModal,
  hideStorageModal,
  showInvalidAmountMessage
}) => {
  const {t, i18n} = useTranslation();
  if (profile === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  const imageProfile = (profile.image || []).map(f => {
    return {
      source: f,
      options: {
        type: 'local'
      }
    };
  });

  let dialog = null;
  const onRemove = () => {
    dialog.show({
      title: t('DELETE_PROFILE'),
      body: t('REVOCATION_PERSONAL_DATA_PROCESSING'),
      actions: [
        Dialog.Action(
          t('YES_DELETE'),
          () => deleteProfile(),
          'btn btn-secondary'
        ),

        Dialog.Action(
          t('NO_CANCEL'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const confirmArchiveSubscription = subscription => () => {
    dialog.show({
      title: t('CONFIRM_ARCHIVE_TITLE'),
      body: t('CONFIRM_ARCHIVE_SUBSCRIPTION'),
      actions: [
        Dialog.Action(
          t('ARCHIVE_BUTTON'),
          () => archiveSubscription(subscription),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('CANCEL'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const onSubmit = profile => {
    if (profile.privacy0) {
      save(profile);
    } else {
      onRemove();
    }
  };

  const findPartner = partnerId => partners.find(p => p.uid === partnerId);

  const storageLimit = {
    xs: 20,
    s: 30,
    m: 50,
    l: 100,
    xl: 200,
    xxl: 500,
    xxxl: 1000,
  }

  const getRtvChannel = (partner, rtvChannelId) => {
    return partner.rtvChannels.find(c => c.id === rtvChannelId);    
  }

  const getRtvName = (partner, rtvChannelId) => {
    const channel = partner.rtvChannels.find(c => c.id === rtvChannelId);
    if (channel) {
      return channel.name;
    } else {
      return null;
    }
  }

  const IsGroupSubscriptionExpired = group => {
    if (group.groupType === groupTypes.rtv) {
      const subscription = subscriptions.find(s => s.rtv_channel_id === group.rtvChannelId && s.kind === subscriptionKind.rtvDedicated)
      return subscription && Date.parse(subscription.expiry) < Date.now();
    }

    return false;
  }

  const renderMemberships = memberships => {
    const partner = findPartner(memberships.partnerId);
    const rtvChannel = partner && memberships.rtvChannelId ? getRtvChannel(partner, memberships.rtvChannelId) : null;

    return (
      <div className="row">
        <div className="col-md-12 col-lg-12 mb-2 mb-md-12 card-group">
          <a href="#" className={rtvChannel && rtvChannel.type === 1 ? "card video_store_bg_rp_specialist ease_fx" : "card video_store_bg_rp ease_fx" }>
            <div className="card-horizontal">
              <div className="col-sm-3 col-lg-4 pl-0 pr-0 video_store_logo_bg_rp d-flex align-items-stretch justify-content-center">
              {
                rtvChannel && rtvChannel.logoNames && rtvChannel.logoNames.length > 0 ?
                  <div className="align-self-center">
                    <img
                      className="img-fluid"
                      src={`${Configuration.apiEndPoint}/partners/rtv_channels/${rtvChannel.id}/images?load=${rtvChannel.logoNames[0]}`}
                      alt={t('LOGO')}
                    />
                  </div> :
                    partner && partner.storeImage && partner.storeImage.length > 0 ?
                      <div className="align-self-center">
                        <img
                          className="img-fluid"
                          src={`${Configuration.apiEndPoint}/images/${partner.uid}?load=${partner.storeImage[0]}`}
                          alt={t('LOGO')}
                        />
                      </div> :
                      <div className="align-self-center video_store_nologo_overlay_rp"/>
                }
              </div>
              <div className="card-body align-items-center col-sm-9 col-lg-8" style={!memberships.accepted || IsGroupSubscriptionExpired(memberships) ? {background:"#505A6F"} : {}}>
                {
                  !memberships.rtvChannelId || !rtvChannel?
                    <h5 className="card-title mb-0">{partner.name}</h5> : 
                    null
                }
                {
                  partner && memberships.rtvChannelId ?
                    <h5 className="card-title mb-0" style={{ color: '#fff'}}>{rtvChannel ? rtvChannel.name : null}</h5> :
                    null
                }
                <Badge variant={memberships.accepted ? "success" : "danger"}>{Object.keys(groupTypes).find(key => groupTypes[key] === memberships.groupType).toUpperCase().replace(/_/g, ' ')}</Badge>
                {
                  !memberships.accepted ?
                  <p className="card-text note_card_rp">{t("GROUP_MEMBERSHIP_PENDING_APPROVAL")}</p> :
                  null
                }   
                {
                  memberships.accepted && IsGroupSubscriptionExpired(memberships) ?
                  <p className="card-text note_card_rp">{t("GROUP_MEMBERSHIP_DISABLED_BY_PARTNER")}</p> :
                  null
                }             
                <p className="card-text note_card_rp">{memberships.description}</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    );
  };

  const renderSubscription = subscription => {
    const partner = findPartner(subscription.partner);
    const rtvChannel = partner && subscription.rtv_channel_id ? getRtvChannel(partner, subscription.rtv_channel_id) : null;
    const hasUpgraded = subscriptions.find(s => new Date(s.expiry) >= new Date() && (s.kind === subscriptionKind.rtvUnlimited || (s.kind === subscriptionKind.rtvDedicated && partner && s.partner === partner.uid && s.rtv_channel_id === subscription.rtv_channel_id)));
    if (subscription.archived)
      return null
  
    return (
      <div className="row">
        <div className="col-md-12 col-lg-12 mb-2 mb-md-12 card-group">
          {
            Date.parse(subscription.expiry) < Date.now() ?
              <a href="#" className={rtvChannel && rtvChannel.type === 1 ? "card video_store_bg_rp_specialist ease_fx" :"card video_store_bg_rp ease_fx"}>
                <div className="card-horizontal">
                  <div className="col-sm-3 col-lg-4 pl-0 pr-0 video_store_logo_bg_rp d-flex align-items-stretch justify-content-center">
                    {
                      rtvChannel && rtvChannel.logoNames && rtvChannel.logoNames.length > 0 ?
                      <div className="align-self-center">
                        <img
                          className="img-fluid"
                          src={`${Configuration.apiEndPoint}/partners/rtv_channels/${rtvChannel.id}/images?load=${rtvChannel.logoNames[0]}`}
                          alt={t('LOGO')}
                        />
                      </div> :
                      partner && partner.storeImage && partner.storeImage.length > 0 ?
                        <div className="align-self-center">
                          <img
                            className="img-fluid"
                            src={`${Configuration.apiEndPoint}/images/${partner.uid}?load=${partner.storeImage[0]}`}
                            alt={t('LOGO')}
                          />
                        </div> :
                        <div className="align-self-center video_store_nologo_overlay_rp"/>
                    }
                  </div>
                  <div className="card-body align-items-center col-sm-9 col-lg-8" style={{background: "#505A6F"}}>
                    <h5 className="card-title mb-0">{subscription.kind === subscriptionKind.rtvEvent ? t('REPLAYER_TV_SPECIAL_EVENT_SUBSCRIPTION') : null}</h5>
                    {
                      partner && subscription.rtv_channel_id ?
                        <h5 className="card-title mb-0" style={{ color: '#fff'}}>{getRtvName(partner, subscription.rtv_channel_id)}</h5> :
                        <h5 className="card-title mb-0">{partner ? partner.name : t('REPLAYER_TV_UNLIMITED')}</h5>
                    }
                    <Badge variant="danger">{subscription.kind === subscriptionKind.rtvUnlimited ? t('UNLIMITED') : subscription.kind === subscriptionKind.rtvDedicated ? t('DEDICATED') : t('SPECIAL_EVENT')}</Badge>
                    <Badge variant="dark">{subscription.cost / 100} €</Badge>
                    <p className="card-text note_card_rp">{t('STARTING_DATE', {date: new Date(subscription.created).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'})})}</p>
                    <p className="card-text note_card_rp">{t('EXPIRED_DATE', {date: new Date(subscription.expiry).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'})})}</p>
                    <Button variant="btn btn-primary" onClick={confirmArchiveSubscription(subscription)} style={{marginRight: 10, marginBottom: 10}}>{t('ARCHIVE_BUTTON')}</Button>
                    {
                      !hasUpgraded ?
                        <Button variant="btn btn-primary" onClick={showReplayerTV(partner, rtvChannel)} style={{marginBottom: 10}}>{t('RENEW')}</Button>
                      : null
                    }
                  </div>
                </div>
              </a>
            :
              subscription.kind !== subscriptionKind.rtvEvent ?
                <a href="#" className={rtvChannel && rtvChannel.type === 1 ? "card video_store_bg_rp_specialist ease_fx" : "card video_store_bg_rp ease_fx"}>
                  <div className="card-horizontal">
                    <div className="col-sm-3 col-lg-4 pl-0 pr-0 video_store_logo_bg_rp d-flex align-items-stretch justify-content-center">
                      {
                        rtvChannel && rtvChannel.logoNames && rtvChannel.logoNames.length > 0 ?
                        <div className="align-self-center">
                          <img
                            className="img-fluid"
                            src={`${Configuration.apiEndPoint}/partners/rtv_channels/${rtvChannel.id}/images?load=${rtvChannel.logoNames[0]}`}
                            alt={t('LOGO')}
                          />
                        </div> :
                        partner && partner.storeImage && partner.storeImage.length > 0 ?
                          <div className="align-self-center">
                            <img
                              className="img-fluid"
                              src={`${Configuration.apiEndPoint}/images/${partner.uid}?load=${partner.storeImage[0]}`}
                              alt={t('LOGO')}
                            />
                          </div> :
                          <div className="align-self-center video_store_nologo_overlay_rp"/>
                      }
                    </div>
                    <div className="card-body align-items-center col-sm-9 col-lg-8">
                      {
                        partner && subscription.rtv_channel_id ?
                          <h5 className="card-title mb-0" style={{ color: '#fff'}}>{getRtvName(partner, subscription.rtv_channel_id)}</h5> :
                          <h5 className="card-title mb-0">{partner ? partner.name : t('REPLAYER_TV_UNLIMITED')}</h5>
                      }
                      <Badge variant="success">{subscription.kind === subscriptionKind.rtvUnlimited ? t('UNLIMITED') : t('DEDICATED')}</Badge>
                      <Badge variant="dark">{subscription.cost / 100} €</Badge>
                      <Form.Group>
                        <Form.Check name="autorenew" type="checkbox" label={t('SUBSCRIPTION_AUTORENEW')} checked={subscription.autorenew} onChange={changeAutorenew(subscription)}/>
                      </Form.Group>
                      <p className="card-text note_card_rp">{t('STARTING_DATE', {date: new Date(subscription.created).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'})})}</p>
                      <p className="card-text note_card_rp">{t('EXPIRY_DATE', {date: new Date(subscription.expiry).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'})})}</p>
                    </div>
                  </div>
                </a>
              :
                <a href="#" className="card video_store_bg_rp_special_event ease_fx">
                  <div className="card-horizontal">
                    <div className="col-sm-3 col-lg-4 pl-0 pr-0 video_store_logo_bg_rp d-flex align-items-stretch justify-content-center">
                      {
                        rtvChannel && rtvChannel.logoNames && rtvChannel.logoNames.length > 0 ?
                        <div className="align-self-center">
                          <img
                            className="img-fluid"
                            src={`${Configuration.apiEndPoint}/partners/rtv_channels/${rtvChannel.id}/images?load=${rtvChannel.logoNames[0]}`}
                            alt={t('LOGO')}
                          />
                        </div> :
                        partner && partner.storeImage && partner.storeImage.length > 0 ?
                          <div className="align-self-center">
                            <img
                              className="img-fluid"
                              src={`${Configuration.apiEndPoint}/images/${partner.uid}?load=${partner.storeImage[0]}`}
                              alt={t('LOGO')}
                            />
                          </div> :
                          <div className="align-self-center video_store_nologo_overlay_rp"/>
                      }
                    </div>
                    {
                      !hasUpgraded ?
                        <div className="card-body align-items-center col-sm-9 col-lg-8">
                          <h5 className="card-title mb-0">{t('REPLAYER_TV_SPECIAL_EVENT_SUBSCRIPTION')}</h5>
                          <h5 className="card-title mb-0">{getRtvName(partner, subscription.rtv_channel_id)}</h5>
                          <Badge variant="success">{t('SPECIAL_EVENT')}</Badge>
                          <Badge variant="dark">{subscription.cost / 100} €</Badge>
                          <p className="card-text note_card_rp">{t('STARTING_DATE', {date: new Date(subscription.created).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'})})}</p>
                          <p className="card-text note_card_rp">{t('EXPIRY_DATE', {date: new Date(subscription.expiry).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'})})}</p>
                          <Button variant="btn btn-primary" onClick={showReplayerTV(partner, rtvChannel)}>
                            {t('UPGRADE')}
                          </Button>
                        </div>
                      :
                        <div className="card-body align-items-center col-sm-9 col-lg-8" style={{background:"#505A6F"}}>
                          <h5 className="card-title mb-0">{t('REPLAYER_TV_SPECIAL_EVENT_SUBSCRIPTION')}</h5>
                          <h5 className="card-title mb-0">{getRtvName(partner, subscription.rtv_channel_id)}</h5>
                          <Badge variant="success">{t('SPECIAL_EVENT')}</Badge>
                          <Badge variant="dark">{subscription.cost / 100} €</Badge>
                          <p className="card-text note_card_rp">{t('STARTING_DATE', {date: new Date(subscription.created).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'})})}</p>
                          <p className="card-text note_card_rp">{t('EXPIRY_DATE', {date: new Date(subscription.expiry).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'})})}</p>
                        </div>
                    }
                  </div>
                </a> 
              }
        </div>
      </div>
    );
  };

  const subscriptionsMinusMembership = subscriptions.filter(s => {
    if (!s.rtv_channel_id) {
      return true;
    }

    return !selectedPlayerGroups.some(g => g.groupType == groupTypes.rtv && g.rtvChannelId === s.rtv_channel_id && g.accepted);
  })

  const isValidTopupAmount = (amount) => {
    return amount === undefined || amount === "" || (amount >= 5 && amount <= 9999);
  };
  
  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 100}}>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} sm={8} md={6}>
              <Tabs defaultActiveKey="profile" className="player-profile-tabs">
                <Tab eventKey="profile" title={t('PROFILE')} tabClassName="player-profile-tab">
                  <Form.Label style={{marginTop: 20}}>{t('PROFILE_PHOTO')}</Form.Label>
                    <FilePond
                      files={imageProfile}
                      server={
                        {
                          url: `${Configuration.apiEndPoint}/`,
                          process: {
                            url: './images',
                            ondata: formData => {
                              formData.append('image_type', imageTypes.profile);
                              return formData;
                            }
                          },
                          revert: './images',
                          restore: './images',
                          fetch: './images',
                          load: `./images/${profile.uid}?load=`,
                          headers: {Authorization: `Bearer ${token}`},
                          remove: (source, load) => {
                            removeFile(source);
                            load();
                          }
                        }
                      }
                    />
                    <Form.Text className="text-muted">{t('IMAGES_SIZE', {size1: '512', size2: '512'})}</Form.Text>
                  <Formik
                    enableReinitialize
                    initialValues={profile}
                    onSubmit={onSubmit}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      values
                    }) => {
                      return (
                        <Form style={{marginTop: 25}} onSubmit={handleSubmit}>
                          <Form.Group>
                            <Form.Label>{t('NICKNAME')}*</Form.Label>
                            <Form.Control required name="nickname" placeholder={t('NICKNAME')} type="text" maxLength={50} value={values.nickname || ''} onChange={handleChange}/>
                            <Form.Text className="text-muted">
                              {t('NICKNAME')}
                            </Form.Text>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>{t('NAME')}*</Form.Label>
                            <Form.Control required name="name" placeholder={t('NAME')} type="text" maxLength={50} value={values.name || ''} onChange={handleChange}/>
                            <Form.Text className="text-muted">
                              {t('NAME')}
                            </Form.Text>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>{t('SURNAME')}*</Form.Label>
                            <Form.Control required name="surname" type="text" placeholder={t('SURNAME')} maxLength={50} value={values.surname || ''} onChange={handleChange}/>
                            <Form.Text className="text-muted">
                              {t('SURNAME')}
                            </Form.Text>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>{t('BIRTH_DATE')}*</Form.Label><br/>
                            <DatePicker
                              required
                              readOnly
                              showMonthDropdown
                              showYearDropdown
                              peekNextMonth
                              locale="it"
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              dropdownMode="select"
                              maxDate={new Date()}
                              selected={(values.birthDate && new Date(values.birthDate)) || null}
                              onChange={val => setFieldValue('birthDate', val)}/>
                            <Form.Text className="text-muted">
                            {t('BIRTH_DATE')}
                            </Form.Text>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>{t('EMAIL')}*</Form.Label>
                            <Form.Control required name="user.email" type="email" placeholder={t('EMAIL')} value={values.user.email || ''} onChange={handleChange}/>
                            <Form.Text className="text-muted">
                            {t('EMAIL')}
                            </Form.Text>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>{t('TELEPHONE')}*</Form.Label>
                            <Form.Control required name="phoneNumber" type="tel" placeholder={t('TELEPHONE')} value={values.phoneNumber || ''} onChange={handleChange}/>
                            <Form.Text className="text-muted">
                            {t('TELEPHONE')}
                            </Form.Text>
                          </Form.Group>
                          <hr style={{height: '1px', background: '#dd004a', color: '#dd004a', borderColor: '#dd004a'}}/>
                          <h4>{t('TAX_DATA')}</h4>
                          <Form.Label>{t('TAX_DATA_INFO')}</Form.Label>
                          <Form.Group>
                            <Form.Label>{t('BUY_CREDIT_AS')}</Form.Label>
                            <Form.Check required name="isBusiness" type="radio" label={t('PRIVATE')} checked={values.isBusiness === false} onChange={() => setFieldValue('isBusiness', false)}/>
                            <Form.Check required name="isBusiness" type="radio" label={t('BUSINESS')} checked={values.isBusiness === true} onChange={() => setFieldValue('isBusiness', true)}/>
                          </Form.Group>
                          { values.isBusiness === false ?
                          <Form.Group>
                            <Form.Group>
                              <Form.Label>{t('COUNTRY')}*</Form.Label>
                              <Form.Control required name="country" type="text" placeholder={t('COUNTRY')} value={values.country || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                                {t('COUNTRY')}
                              </Form.Text>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>{t('ADDRESS')}*</Form.Label>
                              <Form.Control required name="address" type="text" placeholder={t('ADDRESS_INFO')} value={values.address || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                                {t('ADDRESS_INFO')}
                              </Form.Text>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>{t('CITY')}*</Form.Label>
                              <Form.Control required name="city" type="text" placeholder={t('CITY_INFO')} value={values.city || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                                {t('CITY_INFO')}
                              </Form.Text>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>{t('PROVINCE')}*</Form.Label>
                              <Form.Control required name="province" type="text" placeholder={t('PROVINCE_INFO')} value={values.province || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                                {t('PROVINCE_INFO')}
                              </Form.Text>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>{t('CAP')}*</Form.Label>
                              <Form.Control required name="cap" type="text" placeholder={t('CAP')} value={values.cap || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                                {t('CAP')}
                              </Form.Text>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>{t('FISCAL_CODE')}*</Form.Label>
                              <Form.Control required name="fiscalCode" type="text" placeholder={t('FISCAL_CODE')} value={values.fiscalCode || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                                {t('FISCAL_CODE')}
                              </Form.Text>
                            </Form.Group>
                          </Form.Group>
                          : values.isBusiness === true ?
                          <Form.Group>
                            <Form.Group>
                              <Form.Label>{t('BUSINESS_NAME')}</Form.Label>
                              <Form.Control required name="businessName" placeholder={t('BUSINESS_NAME')} type="text" maxLength="255" value={values.businessName || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                                {t('BUSINESS_NAME')}
                              </Form.Text>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>{t('COUNTRY')}</Form.Label>
                              <Form.Check required name="country" type="radio" label={t('ITALY')} checked={values.country === "Italia"} onChange={() => setFieldValue('country', "Italia")}/>
                              <Form.Check required name="country" type="radio" label={t('OTHER')} checked={values.country !== "Italia" && values.country !== null} onChange={() => setFieldValue('country', "")}/>
                            </Form.Group>
                            {values.country !== "Italia" && values.country !== null ?
                            <Form.Group>
                              <Form.Label>{t('COUNTRY')}*</Form.Label>
                              <Form.Control required name="country" type="text" placeholder={t('COUNTRY')} value={values.country || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                                {t('COUNTRY')}
                              </Form.Text>
                            </Form.Group>
                            : null}
                            <Form.Group>
                              <Form.Label>{t('REGISTERED_OFFICE')}</Form.Label>
                              <Form.Control required name="registeredOffice" placeholder={t('ADDRESS_INFO')} type="text" maxLength="255" value={values.registeredOffice || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                                {t('ADDRESS_INFO')}
                              </Form.Text>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>{t('CITY')}*</Form.Label>
                              <Form.Control required name="city" type="text" placeholder={t('CITY_INFO')} value={values.city || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                                {t('CITY_INFO')}
                              </Form.Text>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>{t('PROVINCE')}*</Form.Label>
                              <Form.Control required name="province" type="text" placeholder={t('PROVINCE_INFO')} value={values.province || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                                {t('PROVINCE_INFO')}
                              </Form.Text>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>{t('CAP')}*</Form.Label>
                              <Form.Control required name="cap" type="text" placeholder={t('CAP')} value={values.cap || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                                {t('CAP')}
                              </Form.Text>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>{t('VAT_NUMBER')}</Form.Label>
                              <Form.Control required name="IVA" placeholder={t('VAT_NUMBER')} type="text" maxLength="20" value={values.IVA || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                                {t('VAT_NUMBER')}
                              </Form.Text>
                            </Form.Group>
                            {values.country === "Italia" ?
                            <Form.Group>
                              <Form.Label>{t('SDI_PEC')}</Form.Label>
                              <Form.Control required name="sdiPec" placeholder={t('SDI_PEC')} type="text" maxLength="255" value={values.sdiPec || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                                {t('SDI_PEC')}
                              </Form.Text>
                            </Form.Group>
                            : null}
                          </Form.Group>
                          : null
                          }
                          <hr style={{height: '1px', background: '#dd004a', color: '#dd004a', borderColor: '#dd004a'}}/>
                          <h4>{t('PRIVACY')}</h4>
                          <Form.Label>{t('PRIVACY_AUTHORIZATIONS')}<a href="http://www.replayer.it" target="_blank" rel="noopener noreferrer"> www.replayer.it </a>{t('LEGAL_INFO')}
                          </Form.Label>
                          <Form.Group>
                            <Form.Check name="privacy0" type="checkbox" label={t('PRIVACY0', {date: new Date(values.privacy0Datetime).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'})})} checked={values.privacy0} onChange={handleChange}/>
                          </Form.Group>
                          <Form.Group>
                            <Form.Check name="privacy1" type="checkbox" label={t('PRIVACY1', {date: new Date(values.privacy1Datetime).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'})})} checked={values.privacy1} onChange={handleChange}/>
                          </Form.Group>
                          <Form.Group>
                            <Form.Check name="privacy2" type="checkbox" label={t('PRIVACY2', {date: new Date(values.privacy2Datetime).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'})})} checked={values.privacy2} onChange={handleChange}/>
                          </Form.Group>
                          <Form.Group>
                            <Form.Check name="privacy3" type="checkbox" label={t('PRIVACY3', {date: new Date(values.privacy3Datetime).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'})})} checked={values.privacy3} onChange={handleChange}/>
                          </Form.Group>
                          <Form.Group>
                            <Form.Check name="privacy4" type="checkbox" label={t('PRIVACY4', {date: new Date(values.privacy4Datetime).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'})})} checked={values.privacy4} onChange={handleChange}/>
                          </Form.Group>
                          <Button type="submit">{t('SAVE')}</Button>
                          <Button type="button" variant="secondary" style={{marginLeft: 10}} onClick={close}>{t('CLOSE_BUTTON')}</Button>
                        </Form>
                      );
                    }}
                  </Formik>
                </Tab>
                <Tab eventKey="resources" title= {t('RESOURCES')} tabClassName="player-profile-tab" style={{marginTop: 20}}>
                  <h4>{t('MY_VIDEO_SPACE')}</h4>
                  <h6>{(Math.floor(profile.videoQuota)/(1000000000)).toFixed(2)}GB/{storageLimit[profile.storageType]}GB</h6>
                  <a href="#" className="btn btn_red_rp mb-3 ml-1 mr-1" onClick={showStorageModal}>{t('MANAGE_SPACE')}</a>
                  {(!profile.isBusiness && profile.country && profile.address && profile.fiscalCode) || (profile.isBusiness && profile.country && profile.businessName && profile.registeredOffice && profile.IVA && profile.sdiPec) ?
                  <Formik
                      enableReinitialize
                      initialValues={profile}
                      onSubmit={onSubmit}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        values
                      }) => {
                        return (
                          <Container>
                            <Form style={{marginTop: 25}} onSubmit={handleSubmit}>
                              <h4>{t('MY_CREDIT')}</h4>
                              <Form.Group>
                                <Form.Label>{t('CREDIT_VALUE', {value: values.user.wallet})}</Form.Label>
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>{t('ACCOUNT_TOPUP')} (€)</Form.Label>
                                <Form.Control required type="number" name="topupAmount" value={values.topupAmount || null} step={1} min={5} max={9999} onChange={handleChange}/>
                                <Form.Text className="text-muted">{t('MIN_MAX',{min: '5,00 €', max: '9999,00 €'})}</Form.Text>
                              </Form.Group>
                              <PayPalButton
                                options={{
                                  clientId: Configuration.paypalClientId,
                                  currency: 'EUR'
                                }}
                                createOrder={(data, actions) => {
                                  if (!isValidTopupAmount(values.topupAmount)) {
                                    showInvalidAmountMessage();
                                    return Promise.reject(new Error('Errore durante l\'operazione'));
                                  }
                                  return actions.order.create({
                                    purchase_units: [{
                                      amount: {
                                        value: values && values.topupAmount ? values.topupAmount : 5
                                      }
                                    }],
                                    application_context: {
                                      locale: 'it-IT',
                                      shipping_preference: 'NO_SHIPPING'
                                    }
                                  }).then(orderID => {
                                    // Send order created to the server
                                    return fetch(`${Configuration.apiEndPoint}/paypal/order_created`, {
                                      method: 'POST',
                                      headers: {
                                        'content-type': 'application/json',
                                        Accept: 'application/json',
                                        Authorization: `Bearer ${token}`
                                      },
                                      mode: 'cors',
                                      body: JSON.stringify({
                                        orderID
                                      })
                                    }).then(res => {
                                      // TODO: Mostrare errore in caso di fallimento di questa richiesta
                                      return res.json();
                                    }).then(data => {
                                      return orderID;
                                    });
                                  });
                                }}
                                onApprove={(data, actions) => {
                                  showNotClosePaypalMessage();
                                  // Capture the funds from the transaction
                                  return actions.order.capture().then(details => {
                                    // Send approved order to the server
                                    return fetch(`${Configuration.apiEndPoint}/paypal/order_approved`, {
                                      method: 'POST',
                                      headers: {
                                        'content-type': 'application/json',
                                        Accept: 'application/json',
                                        Authorization: `Bearer ${token}`
                                      },
                                      mode: 'cors',
                                      body: JSON.stringify({
                                        orderID: data.orderID
                                      })
                                    }).then(res => {
                                      return res.json();
                                    }).then(() => {
                                      topUpWalletSuccess();
                                    });
                                  });
                                }}
                                onShippingChange={(data,actions) => {
                                  return actions.resolve();
                                }}
                              />
                            </Form>
                            <div style={{border: '1px solid #DD004A', padding: 20, marginTop: 30}}>
                              <h4>{t('WARNING')}</h4>
                              <p>{t('ACCOUNT_TOPUP_NOTES')}</p>
                            </div>
                          </Container>
                        );
                      }}
                  </Formik>
                  :
                  <Fragment>
                    <h4>{t('MY_CREDIT')}</h4>
                    <Form.Group>
                      <Form.Label>{t('CREDIT_VALUE', {value: profile.user.wallet})}</Form.Label>
                    </Form.Group>
                    <Form.Group>
                      <h4>{t('ACCOUNT_TOPUP')}</h4>
                      <Form.Label>{t('TAX_DATA_NOT_GIVEN_TAB')}</Form.Label>
                      </Form.Group>
                  </Fragment>
                  }
                </Tab>
                <Tab eventKey="memberships" title= {t('MEMBERSHIPS')} tabClassName="player-profile-tab" style={{marginTop: 20}}>
                  {selectedPlayerGroups.map(g => renderMemberships(g))}
                  {selectedPlayerGroups.length === 0 ? t('NO_AFFILIATIONS') : null}
                </Tab>
                <Tab eventKey="subscriptions" title={t('SUBSCRIPTIONS')} tabClassName="player-profile-tab" style={{marginTop: 20}}>
                  {subscriptionsMinusMembership.map(g => renderSubscription(g))}
                  {subscriptionsMinusMembership.length === 0 ? t('NO_SUBSCRIPTIONS') : null}
                </Tab>
                <Tab eventKey="archives" title={t('ARCHIVES')} tabClassName="player-profile-tab" style={{marginTop: 20}}>
                  <Formik
                    enableReinitialize
                    initialValues={{start: null, end: null}}
                    onSubmit={createTransactionReport}
                  >
                    {({
                      handleSubmit,
                      setFieldValue,
                      values
                    }) => {
                      return (
                        <Fragment>
                          <CommonMetaTag/>
                          <Container>
                            <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                              <Row className="justify-content-center">
                                <h4 style={{marginTop: 30}}>{t('REGISTER_TRANSACTIONS')}</h4>
                              </Row>
                              <Row className="justify-content-center">
                                <p style={{marginTop: 30, textAlign: 'center'}}>{t('REGISTER_TRANSACTIONS_INFO')}</p>
                              </Row>
                              <Row className="justify-content-center">
                                <p style={{marginTop: 30, textAlign: 'center'}}>{t('SELECT_PERIOD')}</p>
                              </Row>
                              <Form onSubmit={handleSubmit}>
                                <Form.Row>
                                  <Col>
                                    <Form.Group>
                                      <Form.Label>{t('START_DATE')}</Form.Label><br/>
                                      <DatePicker
                                        locale="it"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        selected={(values.start && new Date(values.start)) || null}
                                        onChange={val => setFieldValue('start', val ? new Date(val).toISOString() : null)}/>
                                    </Form.Group>
                                  </Col>
                                  <Col>
                                    <Form.Group>
                                      <Form.Label>{t('END_DATE')}</Form.Label><br/>
                                      <DatePicker
                                        locale="it"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        selected={(values.end && new Date(values.end).addDays(-2)) || null}
                                        onChange={val => setFieldValue('end', val ? new Date(val).addDays(2).toISOString() : null)}/>
                                    </Form.Group>
                                  </Col>
                                </Form.Row>
                                <Button type="submit" variant="secondary" style={{marginTop: 50, marginBottom: 50}}>{t('EXTRACT_XLS')}</Button>
                              </Form>
                            </Row>
                          </Container>
                        </Fragment>
                      );
                    }}
                  </Formik>
                  <Formik
                    enableReinitialize
                    initialValues={{start: null, end: null}}
                    onSubmit={createBookingReport}
                  >
                    {({
                      handleSubmit,
                      setFieldValue,
                      values
                    }) => {
                      return (
                        <Fragment>
                          <CommonMetaTag/>
                          <Container>
                            <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                              <Row className="justify-content-center">
                                <h4 style={{marginTop: 30}}>{t('REGISTER_ARCHIVED_RESERVATIONS')}</h4>
                              </Row>
                              <Row className="justify-content-center">
                                <p style={{marginTop: 30, textAlign: 'center'}}>{t('REGISTER_ARCHIVED_RESERVATIONS_INFO')}</p>
                              </Row>
                              <Row className="justify-content-center">
                                <p style={{marginTop: 30, textAlign: 'center'}}>{t('SELECT_PERIOD')}</p>
                              </Row>
                              <Form onSubmit={handleSubmit}>
                                <Form.Row>
                                  <Col>
                                    <Form.Group>
                                      <Form.Label>{t('START_DATE')}</Form.Label><br/>
                                      <DatePicker
                                        locale="it"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        selected={(values.start && new Date(values.start)) || null}
                                        onChange={val => setFieldValue('start', val ? new Date(val).toISOString() : null)}/>
                                    </Form.Group>
                                  </Col>
                                  <Col>
                                    <Form.Group>
                                      <Form.Label>{t('END_DATE')}</Form.Label><br/>
                                      <DatePicker
                                        locale="it"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        selected={(values.end && new Date(values.end)) || null}
                                        onChange={val => setFieldValue('end', val ? new Date(val).toISOString() : null)}/>
                                    </Form.Group>
                                  </Col>
                                </Form.Row>
                                <Button type="submit" variant="secondary" style={{marginTop: 50, marginBottom: 50}}>{t('EXTRACT_XLS')}</Button>
                              </Form>
                            </Row>
                          </Container>
                        </Fragment>
                      );
                    }}
                  </Formik>
                  <Formik
                    enableReinitialize
                    initialValues={{start: null, end: null}}
                    onSubmit={createSubscriptionReport}
                  >
                    {({
                      handleSubmit,
                      setFieldValue,
                      values
                    }) => {
                      return (
                        <Fragment>
                          <CommonMetaTag/>
                          <Container>
                            <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                              <Row className="justify-content-center">
                                <h4 style={{marginTop: 30}}>{t('REGISTER_ARCHIVED_SUBSCRIPTIONS')}</h4>
                              </Row>
                              <Row className="justify-content-center">
                                <p style={{marginTop: 30, textAlign: 'center'}}>{t('REGISTER_ARCHIVED_SUBSCRIPTIONS_INFO')}</p>
                              </Row>
                              <Row className="justify-content-center">
                                <p style={{marginTop: 30, textAlign: 'center'}}>{t('SELECT_PERIOD')}</p>
                              </Row>
                              <Form onSubmit={handleSubmit}>
                                <Form.Row>
                                  <Col>
                                    <Form.Group>
                                      <Form.Label>{t('START_DATE')}</Form.Label><br/>
                                      <DatePicker
                                        locale="it"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        selected={(values.start && new Date(values.start)) || null}
                                        onChange={val => setFieldValue('start', val ? new Date(val).toISOString() : null)}/>
                                    </Form.Group>
                                  </Col>
                                  <Col>
                                    <Form.Group>
                                      <Form.Label>{t('END_DATE')}</Form.Label><br/>
                                      <DatePicker
                                        locale="it"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        selected={(values.end && new Date(values.end)) || null}
                                        onChange={val => setFieldValue('end', val ? new Date(val).toISOString() : null)}/>
                                    </Form.Group>
                                  </Col>
                                </Form.Row>
                                <Button type="submit" variant="secondary" style={{marginTop: 50, marginBottom: 50}}>{t('EXTRACT_XLS')}</Button>
                              </Form>
                            </Row>
                          </Container>
                        </Fragment>
                      );
                    }}
                  </Formik>
                </Tab>
              </Tabs>
            </Col>
          </Row>
          <PlayerNewSubscription/>
          <PlayerInsufficientFunds/>
        </Container>
      </Fade>
      <Dialog
        ref={el => {
          dialog = el;
        }}/>
      <Modal show={isStorageModalVisible} onHide={hideStorageModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('MY_VIDEO_MANAGE_SPACE')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Fragment>
            {t('MY_VIDEO_CHOOSE_SPACE')}
            <Formik
              initialValues={{storageType: profile.storageType}}
              onSubmit={changeStorageType}
            >
              {({
                handleSubmit,
                setFieldValue,
                values
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Check required name="storageType" type="radio" label={t('STORAGE_PRICE_XS',{price: storagePrices.xs})} checked={values.storageType === "xs"} onChange={() => setFieldValue('storageType', "xs")}/>
                      <Form.Check required name="storageType" type="radio" label={t('STORAGE_PRICE_S',{price: storagePrices.s})} checked={values.storageType === "s"} onChange={() => setFieldValue('storageType', "s")}/>
                      <Form.Check required name="storageType" type="radio" label={t('STORAGE_PRICE_M',{price: storagePrices.m})} checked={values.storageType === "m"} onChange={() => setFieldValue('storageType', "m")}/>
                      <Form.Check required name="storageType" type="radio" label={t('STORAGE_PRICE_L',{price: storagePrices.l})} checked={values.storageType === "l"} onChange={() => setFieldValue('storageType', "l")}/>
                      <Form.Check required name="storageType" type="radio" label={t('STORAGE_PRICE_XL',{price: storagePrices.xl})} checked={values.storageType === "xl"} onChange={() => setFieldValue('storageType', "xl")}/>
                      <Form.Check required name="storageType" type="radio" label={t('STORAGE_PRICE_XXL',{price: storagePrices.xxl})} checked={values.storageType === "xxl"} onChange={() => setFieldValue('storageType', "xxl")}/>
                      <Form.Check required name="storageType" type="radio" label={t('STORAGE_PRICE_XXXL',{price: storagePrices.xxxl})} checked={values.storageType === "xxxl"} onChange={() => setFieldValue('storageType', "xxxl")}/>
                    </Form.Group>
                    <Button type="submit" variant="secondary" style={{marginBottom: 50}}>{t('SAVE')}</Button>
                  </Form>
                );
              }}
            </Formik>
          </Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={hideStorageModal}>
            {t('CLOSE_BUTTON')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

PlayerProfile.propTypes = {
  profile: PropTypes.object,
  save: PropTypes.func.isRequired,
  deleteProfile: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  token: PropTypes.string,
  removeFile: PropTypes.func.isRequired,
  topUpWalletSuccess: PropTypes.func.isRequired,
  showNotClosePaypalMessage: PropTypes.func.isRequired,
  partners: PropTypes.array,
  subscriptions: PropTypes.array,
  selectedPlayerGroups: PropTypes.array,
  changeAutorenew: PropTypes.func.isRequired,
  archiveSubscription: PropTypes.func.isRequired,
  showReplayerTV: PropTypes.func.isRequired,
  createBookingReport: PropTypes.func.isRequired,
  createSubscriptionReport: PropTypes.func.isRequired,
  storagePrices: PropTypes.object,
  changeStorageType: PropTypes.func.isRequired,
  isStorageModalVisible: PropTypes.bool.isRequired,
  showStorageModal: PropTypes.func.isRequired,
  hideStorageModal: PropTypes.func.isRequired,
};

PlayerProfile.defaultProps = {
  profile: null,
  token: null,
  partners: [],
  subscriptions: [],
  selectedPlayerGroups: [],
  storagePrices: {},
  isStorageModalVisible: false,
};

const mapStateToProps = ({player: {isStorageModalVisible}}) => ({
  isStorageModalVisible,
});

const mapDispatchToProps = dispatch => ({
  changeAutorenew: (subscription) => () => {
    subscription.autorenew = !subscription.autorenew
    dispatch(changeSubscription(subscription));
  },
  archiveSubscription: (subscription) => {
    subscription.archived = !subscription.archived
    dispatch(changeSubscription(subscription));
  },
  createBookingReport: values => {
    dispatch(reportBookings(values));
  },
  createSubscriptionReport: values => {
    dispatch(reportSubscriptions(values));
  },
  createTransactionReport: values => {
    dispatch(reportTransactions(values));
  },
  changeStorageType: newStorageType => {
    dispatch(changeStorageType(newStorageType));
    dispatch(hideStorageModal());
  },
  showStorageModal: () => {
    dispatch(showStorageModal());
  },
  hideStorageModal: () => {
    dispatch(hideStorageModal());
  },
  showInvalidAmountMessage: () => {
    dispatch(showMessage('ACCOUNT_TOPUP', 'MIN_MAX',{min: '5,00 €', max: '9999,00 €'}));
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerProfile));
